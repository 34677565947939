<div *ngFor="let sessionLineItem of session.lineItems" class="row mt-3 session-line-item">
  <div class="col-8">
    <p class="mb-0">{{ sessionLineItem.name}}</p>
    <p class="font-weight-light mb-0">{{ sessionLineItem.description }}</p>
    <p class="font-weight-light text-muted">Prezzo unitario {{ sessionLineItem.amount | fromCents | currency }} •
      Quantità
      {{ sessionLineItem.quantity }}</p>
  </div>
  <div class="col-4">
    <p class="text-right">{{ sessionLineItem.amount * sessionLineItem.quantity | fromCents | currency }}</p>
  </div>
</div>
