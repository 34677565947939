<div class="container-fluid">
  <div class="row d-sm-none">
    <div class="col">
      <checkout-header [session]="session"></checkout-header>
    </div>
  </div>
  <div class="row mt-md-5">
    <div class="col-12 col-md-6 px-md-5">
      <checkout-header [session]="session" class="d-none d-md-block"></checkout-header>
      <checkout-line-items [session]="session"></checkout-line-items>
    </div>
    <div class="col-12 col-md-6 px-md-5">
      <checkout-payment-form [session]="session"></checkout-payment-form>
    </div>
  </div>
</div>
