import { Pipe, PipeTransform } from '@angular/core';
import { ISession } from '@checkout/common';

@Pipe({
  name: 'sessionTotal',
})
export class SessionTotalPipe implements PipeTransform {
  transform(session: ISession): number {
    return session.lineItems.reduce(
      (total, lineItem) => total + lineItem.amount * lineItem.quantity,
      0
    );
  }
}
