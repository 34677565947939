<div class="row mt-3">
  <div class="col-6"><img alt="logo" class="img-fluid" src="./assets/MWF_noclaim.png" /></div>
  <div class="w-100 d-none d-md-block"></div>
  <div class="col d-sm-none">
    <p class="text-right mb-0">Totale</p>
    <p class="text-right">{{ session | sessionTotal }}</p>
  </div>
  <div class="col d-none d-md-block mt-5">
    <p class="mb-0">Totale</p>
    <p class="mb-0">{{ session | sessionTotal | fromCents | currency }}</p>
    <p>{{ session.lineItems.length }} ordini</p>
  </div>
</div>
