<form (ngSubmit)="onSubmit()" [formGroup]="paymentForm">
  <div class="form-group">
    <label for="email">Email</label>
    <input [ngClass]="{ 'is-invalid': isEmailInvalid }" formControlName="email" class="form-control" id="email"
      type="email" />
    <div *ngIf="isEmailInvalid" class="invalid-feedback">
      <div>Il tuo indirizzo email è incompleto</div>
    </div>
  </div>
  <div class="form-group">
    <label for="cardElement">Informazioni sulla carta</label>
    <div [ngClass]="{ 'is-invalid': isCardInvalid }" #cardElement class="form-control"></div>
    <div *ngIf="isCardInvalid" class="invalid-feedback">
      <div>{{ cardErrors }}</div>
    </div>
  </div>
  <div class="form group">
    <label for="name">Titolare della carta</label>
    <input [ngClass]="{ 'is-invalid': isNameInvalid }" formControlName="name" class="form-control" id="name"
      type="text" />
    <div *ngIf="isNameInvalid" class="invalid-feedback">
      <div>Il titolare della carta è incompleto</div>
    </div>
  </div>
  <hr class="my-4" />
  <div *ngIf="hasOtherErrors" class="alert alert-danger my-4">{{ otherErrors }}</div>
  <button [disabled]="isConfirmingPayment" class="btn btn-primary mb-5 mr-1" type="submit"><span
      *ngIf="isConfirmingPayment" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    Paga ora {{ session | sessionTotal | fromCents | currency }}</button>
  <button (click)="onCancel()" type="button" class="btn btn-link mb-5">Annulla</button>
</form>
