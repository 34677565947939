import { ButtonModule } from 'primeng/button';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { HttpClientModule } from '@angular/common/http';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { LineItemsComponent } from './line-items/line-items.component';
import { MainComponent } from './main/main.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { SessionTotalPipe } from './session-total.pipe';
import { environment } from '../environments/environment';
import { FromCentsPipe } from './from-cents.pipe';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    PaymentFormComponent,
    LineItemsComponent,
    HeaderComponent,
    SessionTotalPipe,
    FromCentsPipe,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    HttpClientModule,
    MessageModule,
    MessagesModule,
    ReactiveFormsModule,
    RouterModule.forRoot([{ path: '', component: AppComponent }]),
  ],
  providers: [
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.defaultCurrencyCode,
    },
    { provide: LOCALE_ID, useValue: environment.localeId },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
