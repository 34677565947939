import { Component, Input } from '@angular/core';
import { ISession } from '@checkout/common';

@Component({
  selector: 'checkout-line-items',
  templateUrl: './line-items.component.html',
  styleUrls: ['./line-items.component.sass'],
})
export class LineItemsComponent {
  @Input() session: ISession;
}
