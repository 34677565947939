import { Component, Input } from '@angular/core';
import { ISession } from '@checkout/common';

@Component({
  selector: 'checkout-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass'],
})
export class MainComponent {
  @Input() session: ISession;
}
