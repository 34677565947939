import { Component, Input } from '@angular/core';
import { ISession } from '@checkout/common';

@Component({
  selector: 'checkout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent {
  @Input() session: ISession;
}
