// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// FIXME: most of the environment variables should be fetched from the api
export const environment = {
  production: false,
  stripe: {
    publishableKey:
      'pk_test_51HIZqJHWLxbmO19sJ4BQfxaGvAYDu6YCPxGlVwzOffbe0AVtY38zOVbeqNMLpD7rIQEUqWCiXFnpJlHjw0ExEIHo00JejgZkEZ',
  },
  defaultCurrencyCode: 'EUR',
  localeId: 'it',
  checkout: {
    publishableKey: '889fd41e4c6a4779cfbb3c89941d2b83',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
