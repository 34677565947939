import { Observable, of } from 'rxjs';

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {
  StripeCardElement,
  PaymentIntent,
  StripeError,
} from '@stripe/stripe-js';

import { StripeService } from '../stripe.service';
import { map, tap, mapTo, flatMap, switchMap } from 'rxjs/operators';
import { ISession } from '@checkout/common';
import { SessionService } from '../session.service';

@Component({
  selector: 'checkout-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.sass'],
})
export class PaymentFormComponent implements AfterViewInit, OnInit {
  @Input() session: ISession;
  @ViewChild('cardElement') cardElement: ElementRef;
  card?: StripeCardElement;
  cardErrors: string;
  didSubmit: boolean = false;
  isConfirmingPayment: boolean = false;
  paymentForm: FormGroup;
  otherErrors: string;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly sessionService: SessionService,
    private readonly stripeService: StripeService
  ) {}

  get hasOtherErrors(): boolean {
    return this.didSubmit && this.otherErrors != null;
  }

  get isCardInvalid(): boolean {
    return this.didSubmit && this.cardErrors != null;
  }

  get isEmailInvalid(): boolean {
    const control = this.paymentForm.controls.email;
    return this.isControlInvalid(control);
  }

  get isNameInvalid(): boolean {
    const control = this.paymentForm.controls.name;
    return this.isControlInvalid(control);
  }

  cancel(): Observable<void> {
    return this.sessionService.cancel(this.session);
  }

  confirmCardPayment(): Observable<{
    paymentIntent?: PaymentIntent;
    error?: StripeError;
  }> {
    return this.stripeService.stripe$.pipe(
      flatMap((stripe) => {
        const clientSecret = this.session.paymentIntent.client_secret;
        return stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: { email: this.email, name: this.name },
          },
        });
      })
    );
  }

  ngAfterViewInit() {
    this.initStripeElements().subscribe();
  }

  ngOnInit(): void {
    const paymentForm = (this.paymentForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
    }));

    paymentForm.valueChanges.subscribe(() => {
      this.didSubmit = false;
    });
  }

  onCancel(): void {
    this.cancel().subscribe();
  }

  onSubmit(): void {
    this.didSubmit = true;

    this.isConfirmingPayment = true;

    this.confirmCardPayment()
      .pipe(
        switchMap(({ error, paymentIntent }) => {
          this.isConfirmingPayment = false;

          if (error != null) {
            const { code } = error;

            if (
              error.type === 'validation_error' &&
              [
                'incomplete_cvc', // this is not documented https://stripe.com/docs/error-codes
                'incomplete_expiry', // this is not documented https://stripe.com/docs/error-codes
                'incomplete_number', // this is not documented https://stripe.com/docs/error-codes
                'incomplete_zip', // this is not documented https://stripe.com/docs/error-codes
                'incorrect_number',
                'incorrect_zip',
                'invalid_cvc',
                'invalid_expiry_month_past', // this is not documented https://stripe.com/docs/error-codes
                'invalid_expiry_month',
                'invalid_expiry_year_past', // this is not documented https://stripe.com/docs/error-codes
                'invalid_expiry_year',
                'invalid_number',
              ].includes(code)
            ) {
              this.cardErrors = error.message;
            } else if (
              error.type === 'invalid_request_error' &&
              code === 'email_invalid'
            ) {
              // do nothing because this overlaps reactive form validation
            } else if (
              error.type === 'invalid_request_error' &&
              code === 'parameter_invalid_empty'
            ) {
              // do nothing because this overlaps reactive form validation
            } else {
              this.otherErrors = error.message;
            }
          } else if (paymentIntent.status === 'succeeded') {
            return this.sessionService.finalize(this.session);
          } else if (paymentIntent.status === 'processing') {
            return this.sessionService.finalize(this.session);
          } else {
            // FIXME: payment has failed
          }

          return of();
        })
      )
      .subscribe();
  }

  private get email(): string {
    return this.paymentForm.controls.email.value;
  }

  private initStripeElements(): Observable<void> {
    return this.stripeService.stripe$.pipe(
      map((stripe) => stripe.elements()),
      tap((elements) => {
        const card = elements.create('card');
        card.mount(this.cardElement.nativeElement);
        card.on('change', (event) => {
          if (!event.empty) {
            this.didSubmit = false;
          }
          this.cardErrors = event.error != null ? event.error.message : null;
        });
        this.card = card;
      }),
      mapTo(void 0)
    );
  }

  private isControlInvalid(control: AbstractControl): boolean {
    return this.didSubmit && control.invalid;
  }

  private get name(): string {
    return this.paymentForm.controls.name.value;
  }
}
