<div *ngIf="session$ | async as session; then thenBlock else elseBlock"></div>
<ng-template #thenBlock let-session>
  <checkout-main [session]="session"></checkout-main>
</ng-template>
<ng-template #elseBlock>
  <div class="align-items-center d-flex h-100 justify-content-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">Caricamento in corso...</span>
    </div>
  </div>
</ng-template>
