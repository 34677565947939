import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISession, IResponse } from '@checkout/common';
import { map } from 'rxjs/operators';
import { environment } from '../environments';

const headers = new HttpHeaders({
  'Content-Type': 'application/json; charset=utf-8',
  'X-Api-Key': environment.checkout.publishableKey,
});

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor(private readonly http: HttpClient) {}

  cancel(session: ISession): Observable<void> {
    return this.http
      .post<IResponse<ISession>>(
        `/api/sessions/${session.id}/cancel`,
        {},
        { headers }
      )
      .pipe(
        map((elem: IResponse<ISession>) => {
          window.open(elem.data.cancelUrl, '_self');
        })
      );
  }

  finalize(session: ISession): Observable<void> {
    return this.http
      .post<IResponse<ISession>>(
        `/api/sessions/${session.id}/success`,
        {},
        { headers }
      )
      .pipe(
        map((elem: IResponse<ISession>) => {
          window.open(elem.data.successUrl, '_self');
        })
      );
  }

  getBySessionId(sessionId: string): Observable<ISession> {
    return this.http
      .get<IResponse<ISession>>(`/api/sessions/${sessionId}`, {
        headers,
      })
      .pipe(map((response: IResponse<ISession>) => response.data));
  }
}
