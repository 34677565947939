import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { environment } from '../environments/environment';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  readonly stripe$: Observable<Stripe>;
  constructor() {
    this.stripe$ = from(
      loadStripe(environment.stripe.publishableKey, { locale: 'it' })
    );
  }
}
