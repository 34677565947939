import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { ISession } from '@checkout/common';
import { SessionService } from './session.service';

@Component({
  selector: 'checkout-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  session$: Observable<ISession>;

  constructor(
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.session$ = this.route.queryParams.pipe(
      map(this.pickSessionId),
      filter(this.isDefined),
      mergeMap(this.getSessionById)
    );
  }

  private getSessionById = (sessionId: string): Observable<ISession> => {
    return this.sessionService.getBySessionId(sessionId);
  };

  private isDefined = (value: any): boolean => value != null;

  private pickSessionId = (params: Params): string => params.sessionId;
}
